import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { LoginComponent } from './login/login.component';


import { NgbModule, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import { FormComponent } from './form/form.component';
import { BasicAuthInterceptor } from './common/basicauth.interceptor';
import { SupervisorComponent } from './supervisor/supervisor.component';
import { HistoryComponent } from './history/history.component';
import { ToastrModule } from 'ngx-toastr';
import { TermsComponent } from './terms/terms.component';
import { DateParserFormatter } from './common/date.formatter';

registerLocaleData(localeEn, 'en');

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    FormComponent,
    SupervisorComponent,
    HistoryComponent,
    TermsComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    AngularFontAwesomeModule,
    HttpClientModule,
    AppRoutingModule,
    NgbModule,
    ToastrModule.forRoot(),
    BrowserAnimationsModule
  ],
  entryComponents: [SupervisorComponent, HistoryComponent, TermsComponent],
  providers: [
    { provide: NgbDateParserFormatter, useClass: DateParserFormatter },
    { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'en' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
