import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../common/auth.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    loginForm = this.fb.group({
        userName: ['', Validators.required],
        password: ['', Validators.required]
    });
    loginFailure: boolean;
    working: boolean;

    constructor(private fb: FormBuilder, private authService: AuthService, private router: Router, private toastr: ToastrService) {
		this.loginFailure = false;
        this.working = false;
    }

    ngOnInit() {
    }

    async onSubmit() {
        this.working = true;
        this.authService.login(this.loginForm.value).then(() => {
            this.working = false;
            this.router.navigate(['/form']);
        }).catch((err) => {
            this.working = false;
            this.toastr.error(err.error && err.error.messages ? err.error.messages[0].desc : 'something went wrong', 'Error Message');
            console.log(err);
        });
    }
}
