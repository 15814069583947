import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';




@Injectable()
export class DateParserFormatter implements NgbDateParserFormatter {

    parse(value: string): NgbDateStruct {
        if (value) {
            const dateParts: Array<any> = value.trim().split('/');

            if (dateParts.length === 1 && Number(dateParts[0])) {
                return {
                    day: Number(dateParts[0]),
                    month: null,
                    year: null
                };
            } else if (dateParts.length === 2 && Number(dateParts[0]) && Number(dateParts[1])) {
                return {
                    day: Number(dateParts[0]),
                    month: Number(dateParts[1]),
                    year: null
                };
            } else if (dateParts.length === 3 && Number(dateParts[0]) && Number(dateParts[1]) && Number(dateParts[2])) {
                return {
                    day: Number(dateParts[0]),
                    month: Number(dateParts[1]),
                    year: Number(dateParts[2])
                };
            }
        }

        return null;
    }


    format(date: NgbDateStruct): string {
        return date ?
            `${Number(date.day) ? date.day : ''}/${Number(date.month) ? date.month : ''}/${date.year}` :
            '';
    }

}
