export const environment = {
    production: false,
    apiServer: {
        basePath: 'https://balfin.def-uat.qivos.net/api'
    },
    schemaCode: '0000',
    formBatchCode: 'BALFIN_DATA_ENTRY',
    countryCode: 'al',
    languageCode: 'al',
    regionCode: 'GR-A1'
};
