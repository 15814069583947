import {
  Component,
  OnInit,
  HostListener,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { AuthService } from '../common/auth.service';
import { Router } from '@angular/router';
import { APIService } from '../api.service';
import { Schema } from '../common/dto/schema.model';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Salutation } from '../common/dto/salutation.model';
import { LockForm } from '../common/dto/lock.form.model';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Invoice } from '../common/dto/invoice.model';
import { environment } from 'src/environments/environment';
import { Member } from '../common/dto/member.model';
import { EmailData } from '../common/dto/email.data.model';
import { TelephoneData } from '../common/dto/telephone.model';
import { ConsentData } from '../common/dto/consent.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SupervisorComponent } from '../supervisor/supervisor.component';
import { HistoryComponent } from '../history/history.component';
import { ToastrService } from 'ngx-toastr';
import { TermsComponent } from '../terms/terms.component';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SupervisorMark } from '../common/dto/supervisor.mark.model';
import * as moment from 'moment';
import { splitAtColon } from '@angular/compiler/src/util';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class FormComponent implements OnInit {
  @ViewChild('regDate', null) regDate: ElementRef;
  @ViewChild('firstName', null) firstName: ElementRef;

  imageLeft: number;
  imageTop: number;
  imageScale: number;
  imageRotated: boolean;
  schemas: Schema[];
  formInfo: LockForm;
  salutations: Salutation[];
  lastInvoice: Invoice;
  imageForm: SafeResourceUrl;
  submitted: boolean;
  member: Member;
  emailData: EmailData;
  telephoneData: TelephoneData;
  consentData: ConsentData;
  currentDate: string;
  minDate: string;
  autocompleteTown: any[];
  invalidDates: string[];
  invalidDate: boolean;
  invalidRegDate: boolean;
  counter: number;
  supervisorMark: SupervisorMark;
  invalidAge: boolean;

  regForm = this.fb.group({
    batch: ['', Validators.required],
    loyaltyMembershipData: this.fb.group({
      schemaCode: [{ value: '', disabled: true }],
      preallocatedCard: [
        '',
        [
          Validators.required,
          Validators.maxLength(13),
          Validators.minLength(13),
          Validators.pattern('^[0-9]*$'),
        ],
      ],
    }),
    salutation: ['', Validators.required],
    firstName: ['', Validators.pattern('^[^@#$%&*0-9]+$')],
    lastName: ['', Validators.pattern('^[^@#$%&*0-9]+$')],
    addressData: this.fb.group({
      addressLine2: ['', Validators.pattern('^[^@#$%&*]+$')],
      town: ['', Validators.pattern('^[^@#$%&*0-9]+$')],
    }),
    mobile: [{ value: '', disabled: true }, Validators.pattern('^06[0-9]{8}$')],
    email: [
      '',
      Validators.pattern(/^\w+([\.-]?\w+)*@\w{2,}([\.-]?\w+)*(\.\w{2,3})+$/),
    ],
    dateOfBirth: [
      '',
      Validators.pattern('^[0-9]{1,2}(/|-)[0-9]{1,2}(/|-)[0-9]{4}'),
    ],
    consent: [false],
    registrationDate: [{ value: '', disabled: true }, Validators.required],
  });

  constructor(
    private authService: AuthService,
    private router: Router,
    private apiService: APIService,
    private fb: FormBuilder,
    private sanitizer: DomSanitizer,
    private modalService: NgbModal,
    private toastr: ToastrService
  ) {
    this.init();
    this.formInfo = {} as LockForm;
    this.schemas = [];
    this.salutations = [];
    this.lastInvoice = null;
    this.member = {} as Member;
    this.emailData = {} as EmailData;
    this.telephoneData = {} as TelephoneData;
    this.consentData = {} as ConsentData;
    this.supervisorMark = {} as SupervisorMark;
    this.invalidDates = ['31/04', '31/06', '31/09', '31/11', '30/02', '31/02'];
    this.invalidDate = false;
    this.currentDate = new Date().toISOString().slice(0, 10);
    this.minDate = new Date('2019-07-15').toISOString().slice(0, 10);
    this.invalidRegDate = false;
    this.invalidAge = false;
  }

  async ngOnInit() {
    this.regForm
      .get('loyaltyMembershipData')
      .get('schemaCode')
      .setValue(environment.schemaCode);
    this.regForm.get('batch').setValue(environment.formBatchCode);
    this.firstName.nativeElement.focus();

    this.submitted = false;
    this.formInfo = null;
    this.imageForm = null;

    await this.apiService
      .getUserHistory()
      .then((data) => {
        this.counter = data.length;
      })
      .catch(() => {
        console.log('Could not fetch user counter');
      });

    await this.apiService
      .getSchemas()
      .then((schs) => {
        this.schemas = schs;
      })
      .catch((err) => console.log('Could not fetch schemas'));
    await this.apiService
      .getSalutations()
      .then((sals) => {
        this.salutations = sals;
      })
      .catch((err) => console.log('Could not fetch salutations'));

    try {
      this.formInfo = await this.apiService.getNextAvailableForm();
      await this.setFormValues();
    } catch (err) {
      if (err instanceof HttpErrorResponse && err.status === 404) {
        this.toastr.error('There is no scanned form available', 'Message', {
          disableTimeOut: false,
        });
      }
      console.log(
        'Error while getting next available form ' +
          JSON.stringify(err.error.messages[0])
      );
    }
  }

  // controls and form getters
  get f() {
    return this.regForm.controls;
  }
  get loyaltyControls() {
    return (this.regForm.get('loyaltyMembershipData') as FormGroup).controls;
  }
  get addressControls() {
    return (this.regForm.get('addressData') as FormGroup).controls;
  }

  init() {
    this.imageLeft = 0;
    this.imageTop = 0;
    this.imageScale = 1;
    this.imageRotated = false;
  }

  @HostListener('window:keydown', ['$event'])
  keyboardInput(event: any) {
    this.evaluateKeyboardInput(event);
  }

  evaluateKeyboardInput(event: any) {
    const result = this.determineLastAction(event);

    if (result !== 'NONE') {
      event.preventDefault();
      event.stopPropagation();
    }

    switch (result) {
      case 'CTRL_F9':
        this.imageRotated = !this.imageRotated;
        break;
      case 'CTRL_UP':
        this.imageTop -= 20;
        break;
      case 'CTRL_LEFT':
        this.imageLeft -= 20;
        break;
      case 'CTRL_DOWN':
        this.imageTop += 20;
        break;
      case 'CTRL_RIGHT':
        this.imageLeft += 20;
        break;
      case 'CTRL_ZERO':
        this.init();
        break;
      case 'CTRL_SHIFT_UP':
        this.imageScale += 0.2;
        break;
      case 'CTRL_SHIFT_DOWN':
        this.imageScale -= 0.2;
        break;
      case 'CTRL_F8':
        this.openSupervisorModal();
        break;
      case 'CTRL_F7':
        this.regForm.reset();
        this.ngOnInit();
        break;
      case 'CTRL_F6':
        this.openHistoryModal();
        break;

      default:
        break;
    }
  }

  determineLastAction(event: any): string {
    if (event.ctrlKey && event.shiftKey && event.keyCode === 38) {
      return 'CTRL_SHIFT_UP';
    } else if (event.ctrlKey && event.shiftKey && event.keyCode === 40) {
      return 'CTRL_SHIFT_DOWN';
    } else if (event.ctrlKey && event.keyCode === 38) {
      return 'CTRL_UP';
    } else if (event.ctrlKey && event.keyCode === 40) {
      return 'CTRL_DOWN';
    } else if (event.ctrlKey && event.keyCode === 39) {
      return 'CTRL_RIGHT';
    } else if (event.ctrlKey && event.keyCode === 37) {
      return 'CTRL_LEFT';
    } else if (event.ctrlKey && event.keyCode === 96) {
      return 'CTRL_ZERO';
    } else if (event.ctrlKey && event.keyCode === 120) {
      return 'CTRL_F9';
    } else if (event.ctrlKey && event.keyCode === 119) {
      return 'CTRL_F8';
    } else if (event.ctrlKey && event.keyCode === 118) {
      return 'CTRL_F7';
    } else if (event.ctrlKey && event.keyCode === 117) {
      return 'CTRL_F6';
    } else {
      return 'NONE';
    }
  }

  public async findForm(formCode: string) {
    if (this.formInfo && this.formInfo.formCode === formCode) {
      return;
    }
    if (!formCode) {
      return;
    }

    this.regForm.reset();
    this.regForm
      .get('loyaltyMembershipData')
      .get('schemaCode')
      .setValue(environment.schemaCode);
    this.regForm.get('batch').setValue(environment.formBatchCode);
    this.submitted = false;

    try {
      this.imageForm = null;
      this.formInfo = await this.apiService.findScannedForm(formCode, true);
      this.formInfo.image = await this.apiService.getFormImage(this.formInfo);
      await this.setFormValues();
    } catch (err) {
      this.toastr.error(
        err.error && err.error.messages
          ? err.error.messages[0].desc
          : 'something went wrong',
        'Error Message'
      );
      console.log(err);
    }
  }

  private async setFormValues() {
    if (this.formInfo.image) {
      this.imageForm = this.sanitizer.bypassSecurityTrustResourceUrl(
        window.URL.createObjectURL(this.formInfo.image)
      );
    } else {
      this.toastr.info('No image found for this scanned form');
    }
    this.regForm
      .get('loyaltyMembershipData')
      .get('schemaCode')
      .setValue(environment.schemaCode);
    this.regForm.get('batch').setValue(environment.formBatchCode);
    // set card number
    this.regForm
      .get('loyaltyMembershipData')
      .get('preallocatedCard')
      .setValue(this.formInfo.formCode);
    // search for last invoice to set registration date
    try {
      this.lastInvoice = await this.apiService.getLastInvoiceDate(
        this.formInfo.formCode
      );
    } catch (err) {
      console.log(err.error.messages[0].desc);
    }
    this.setMemberValues();

    if (this.lastInvoice) {
      const registrationDate = new Date(this.lastInvoice.invoiceDate)
        .toISOString()
        .slice(0, 10);
      this.regForm.get('registrationDate').setValue(registrationDate);
    }
  }

  private async setMemberValues() {
    let existingMember = {} as Member;
    await this.apiService
      .getMemberByCard(this.formInfo.formCode)
      .then((data) => {
        existingMember = data;
      })
      .catch((err) => console.log(err));
    if (existingMember !== null) {
      if (existingMember.firstName) {
        this.regForm.get('firstName').setValue(existingMember.firstName);
      }
      if (existingMember.lastName) {
        this.regForm.get('lastName').setValue(existingMember.lastName);
      }
      if (existingMember.dateOfBirth) {
        const bdate = new Date(existingMember.dateOfBirth);
        const year = bdate.getFullYear();
        const month =
          bdate.getMonth() + 1 < 9
            ? '0' + (bdate.getMonth() + 1)
            : bdate.getMonth() + 1;
        const date =
          bdate.getDate() < 10 ? '0' + bdate.getDate() : bdate.getDate();
        this.regForm
          .get('dateOfBirth')
          .setValue(date + '/' + month + '/' + year);
      }
      this.regForm.get('salutation').setValue(existingMember.salutationCode);
      if (existingMember.registrationDate) {
        const registrationDate = new Date(existingMember.registrationDate);
        this.regForm
          .get('registrationDate')
          .setValue(registrationDate.toISOString().slice(0, 10));
      }
      if (existingMember.telephoneList.length > 0) {
        this.regForm
          .get('mobile')
          .setValue(existingMember.telephoneList[0].telephoneNumber);
      }
      if (existingMember.emailList.length > 0) {
        this.regForm
          .get('email')
          .setValue(existingMember.emailList[0].emailAddress);
      }

      if (existingMember.addressList.length > 0) {
        this.regForm
          .get('addressData')
          .get('town')
          .setValue(existingMember.addressList[0].town);
        this.regForm
          .get('addressData')
          .get('addressLine2')
          .setValue(existingMember.addressList[0].addressLine2);
      }
      if (
        existingMember.consentList.length > 0 &&
        existingMember.consentList[0].flag
      ) {
        this.regForm.get('consent').setValue(true);
      } else {
        this.regForm.get('consent').setValue(false);
      }
    } else {
      console.log('Member for this card is not registered yet');
    }
  }

  async onSubmit(consent: boolean) {
    // on submit if registation date is empty set it today
    if (!this.regForm.get('registrationDate').value) {
      this.regForm.get('registrationDate').setValue(this.currentDate);
    }

    this.submitted = true;
    this.invalidRegDate = !this.regDate.nativeElement.checkValidity();
    if (!this.regDate.nativeElement.checkValidity()) {
      return;
    }
    if (this.invalidAge) {
      return;
    }
    if (this.regForm.valid) {
      this.createMemberObject(consent);
      try {
        await this.apiService.createMember(this.member).then(() => {
          this.toastr.success('Member created/updated successfully');
        });
        if (consent) {
          this.apiService
            .updateMemberAttribute(
              this.member.loyaltyMembershipData[0].preallocatedCard
            )
            .subscribe(
              () => console.log('Member terms attribute successfully'),
              (error) => console.log(error)
            );
        }
        await this.apiService
          .processedMark(
            this.formInfo.formCode,
            this.regForm.get('loyaltyMembershipData').get('preallocatedCard')
              .value
          )
          .then(() => {
            this.toastr.info(
              'Successfull billing',
              'Form is marked as processed'
            );
          });
        if (!consent && this.supervisorMark) {
          await this.apiService.supervisorMark(this.supervisorMark).then(() => {
            this.toastr.success('Scanned form marked as supervised');
          });
        }
        this.regForm.reset();
        this.ngOnInit();
      } catch (err) {
        this.toastr.error(
          JSON.stringify(err.error.messages[0].desc),
          'Error Message'
        );
        console.log('CreateMember error is ' + JSON.stringify(err.error));
        this.supervisorMark = null;
        return;
      }
    }
  }

  async logout() {
    await this.authService.logout().then((succ) => {
      this.router.navigate(['/login']);
    });
  }

  private createMemberObject(consent: boolean) {
    this.member.loyaltyMembershipData = [];
    this.member.loyaltyMembershipData[0] =
      this.regForm.value.loyaltyMembershipData;
    this.member.loyaltyMembershipData[0].schemaCode = environment.schemaCode;
    this.member.loyaltyMembershipData[0].registrationCountryCode =
      environment.countryCode;
    this.member.loyaltyMembershipData[0].category = 'NormalMember';
    this.member.addressList = [];
    if (
      this.regForm.value.addressData.addressLine2 &&
      this.regForm.value.addressData.addressLine2.trim()
    ) {
      this.regForm.value.addressData.countryCode = environment.countryCode;
      this.regForm.value.addressData.addressType = 'PRIMARY_PERSON_ADDRESS';
      this.member.addressList[0] = this.regForm.value.addressData;
    }
    this.member.firstName = this.regForm.value.firstName;
    this.member.lastName = this.regForm.value.lastName;
    this.member.registrationDate = this.regForm.value.registrationDate;

    if (this.regForm.value.dateOfBirth) {
      this.member.dateOfBirth = moment(
        this.regForm.value.dateOfBirth,
        'DD-MM-YYYY'
      )
        .hour(12)
        .format('YYYY-MM-DDTHH:mm:ss.SSSZZ');
    }

    this.member.emailList = [];
    if (this.regForm.value.email) {
      this.emailData.emailAddress = this.regForm.value.email;
      this.emailData.isPrimary = true;
      this.member.emailList[0] = this.emailData;
    }
    this.member.telephoneList = [];
    if (this.regForm.value.mobile) {
      this.telephoneData.telephoneNumber = '0600000010';
      this.telephoneData.isPrimary = true;
      this.telephoneData.countryCode = environment.countryCode;
      this.telephoneData.telephoneType = 'MOBILE';
      this.member.telephoneList[0] = this.telephoneData;
    }
    this.addMemberConsents(consent);

    if (this.regForm.value.salutation === 'DEFAULT') {
      this.member.gender = 'UNKNOWN';
    } else {
      this.member.gender = this.regForm.value.salutation;
    }
    this.member.salutationCode = this.regForm.value.salutation;
  }

  private addMemberConsents(consent: boolean) {
    this.member.consentList = [];
    this.member.consentList[0] = this.createMemberConsent('SMS', consent);
    this.member.consentList[1] = this.createMemberConsent('EMAIL', consent);
  }

  private createMemberConsent(type: string, flagValue: boolean) {
    const consentDate = new Date();
    const consent: ConsentData = {
      name: type,
      flag: flagValue,
      updateDate: consentDate,
      metadata: [
        {
          key: 'source',
          value: 'Data entry form',
        },
      ],
    };
    return consent;
  }

  // methods applied on inputs
  addDash(date: string, event: any) {
    if (
      event.inputType === 'deleteContentBackward' &&
      date.substr(date.length - 1) === '/'
    ) {
      return;
    }
    if (date.length === 2 && date.indexOf('/') < 1) {
      this.regForm.get('dateOfBirth').setValue(date + '/');
    } else if (date.length === 5 && date.indexOf('/') < 3) {
      this.regForm.get('dateOfBirth').setValue(date + '/');
    }
    const dateVal: string = this.regForm.get('dateOfBirth').value;

    if (
      event.inputType === 'deleteContentBackward' &&
      dateVal.substr(dateVal.length - 1) === '/'
    ) {
      this.regForm.get('dateOfBirth').setValue(date.substr(0, date.length - 1));
    }
    this.checkValidity(date);
  }

  checkValidity(date: string) {
    if (this.invalidDates.indexOf(date.slice(0, 5)) > -1) {
      this.invalidDate = true;
    } else {
      this.invalidDate = false;
    }

    if (date !== null) {
      const dateOfBirth = moment(date, 'DD-MM-YYYY');
      const currentDate = moment();
      const age = currentDate.diff(dateOfBirth, 'years');
      if (age < 16) {
        this.invalidAge = true;
      } else {
        this.invalidAge = false;
      }
    }
  }

  capitalizeFirstLetter(name: string, firstName: boolean) {
    if (name.indexOf(' ') > 0) {
      name = name
        .toLowerCase()
        .split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');
    } else if (name.indexOf('-')) {
      name = name
        .toLowerCase()
        .split('-')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join('-');
    }
    if (firstName) {
      this.regForm.get('firstName').setValue(name);
    } else {
      this.regForm.get('lastName').setValue(name);
    }
  }

  capitalize(text: string, field: string, group: string) {
    if (group) {
      this.regForm.get(group).get(field).setValue(text.toUpperCase());
    } else {
      this.regForm.get(field).setValue(text.toUpperCase());
    }
  }

  lowerCase(text: string) {
    this.regForm.get('email').setValue(text.toLowerCase());
  }

  lookupTown = (text$: Observable<string>) => {
    return this.apiService.lookupTown(text$);
  };

  validate() {
    this.invalidRegDate = !this.regDate.nativeElement.checkValidity();
  }

  focusNextElement() {
    this.firstName.nativeElement.focus();
  }

  // Modals Supervise history and terms
  async openSupervisorModal() {
    this.submitted = true;
    if (!this.formInfo) {
      this.toastr.error('No scanned form to supervise');
      return;
    }
    if ((await this.regForm.invalid) || this.invalidRegDate) {
      return;
    }
    const modalRef = this.modalService.open(SupervisorComponent);
    modalRef.componentInstance.formCode = this.formInfo.formCode;
    modalRef.componentInstance.supervisorMark.subscribe(
      (data: SupervisorMark) => {
        this.supervisorMark = data;
      }
    );
    modalRef.componentInstance.submitForm.subscribe(
      (receivedEntry: boolean) => {
        if (receivedEntry) {
          this.onSubmit(false);
        }
      }
    );
  }
  openHistoryModal() {
    const modalRef = this.modalService.open(HistoryComponent);
    modalRef.componentInstance.formToEdit.subscribe((receivedEntry: string) => {
      this.findForm(receivedEntry);
    });
  }

  openTermsAndConditions() {
    this.modalService.open(TermsComponent);
  }
}
