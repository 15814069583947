import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class BasicAuthInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with basic auth credentials if available
        let authorization = localStorage.getItem('Authorization');
        if (authorization != null) {
            request = request.clone({
                setHeaders: { 
                    'Authorization': `Basic ${authorization}`
                }
            });
        }

        return next.handle(request);
    }
}