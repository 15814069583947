import { Injectable } from '@angular/core';
import { Observable, throwError, of } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, debounceTime, distinctUntilChanged, map, tap, switchMap } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { Schema } from './common/dto/schema.model';
import { LockFormResponse } from './common/dto/responses/lockform.response';
import { LockForm } from './common/dto/lock.form.model';
import { SchemaResponse } from './common/dto/responses/schema.response';
import { Salutation } from './common/dto/salutation.model';
import { SalutationResponse } from './common/dto/responses/salutation.response';
import { SupervisorCategory } from './common/dto/supervisor.category.model';
import { SupervisorCategoryResponse } from './common/dto/responses/supervisor.category.response';
import { Invoice } from './common/dto/invoice.model';
import { InvoiceResponse } from './common/dto/responses/invoice.response';
import { Login } from './common/dto/login.model';
import { GenericResponse } from './common/dto/responses/generic.response';
import { Member } from './common/dto/member.model';
import { MemberResponse } from './common/dto/responses/member.response';
@Injectable({
    providedIn: 'root'
})
export class APIService {

    constructor(private http: HttpClient) { }


    async getNextAvailableForm(): Promise<LockForm> {
        let lockInfo = {} as LockForm;
        lockInfo = await this.lockNextAvailableForm();
        await this.getFormImage(lockInfo).then((data) => {
            lockInfo.image = data;
        });
        return lockInfo;
    }

    getFormImage(lockInfo: LockForm): Promise<Blob> {
        const params = new HttpParams({
            fromObject: {
                lockedToken: lockInfo.token
            }
        });

        return this.http.get(`${environment.apiServer.basePath}/scanned-forms/${lockInfo.formCode}/image`,
            { withCredentials: true, responseType: 'blob' as 'blob', params })
            .pipe(
                catchError(err => {
                    return throwError(err);
                })
            ).toPromise();
    }


    lockNextAvailableForm(): Promise<LockForm> {
        const params = new HttpParams({
            fromObject: {
                schemaCode: environment.schemaCode,
                batchCode: environment.formBatchCode,
            }
        });
        return this.http.get<LockFormResponse>
            (`${environment.apiServer.basePath}/scanned-forms/find-next-available?`, { params, withCredentials: true })
            .pipe(
                map(rsp => {
                    return rsp.payload;
                }),
                catchError(err => {
                    return throwError(err);
                })
            )
            .toPromise();
    }

    getSalutations(): Promise<Salutation[]> {
        const params = new HttpParams({
            fromObject: {
                languageCode: environment.languageCode,
                onlyKnownGender: 'false'
            }
        });
        return this.http.get<SalutationResponse>(`${environment.apiServer.basePath}/salutations`,
            { withCredentials: true, params })
            .pipe(
                map(rsp => {
                    return rsp.payload;
                }),
                catchError((err) => {
                    return throwError(err);
                })
            ).toPromise();
    }

    findScannedForm(formCodeVal: string, isUploadedVal: boolean): Promise<LockForm> {

        const params = new HttpParams({
            fromObject: {
                formCode: formCodeVal,
                batchCode: environment.formBatchCode,
                isUploaded: isUploadedVal.toString(),

            }
        });
        return this.http.get<LockFormResponse>
            (`${environment.apiServer.basePath}/scanned-forms/find-scanned-form`, { params, withCredentials: true })
            .pipe(
                map(rsp => {
                    return rsp.payload;
                }),
                catchError(err => {
                    return throwError(err);
                })
            )
            .toPromise();
    }


    getSchemas(): Promise<Schema[]> {
        return this.http.get<SchemaResponse>(`${environment.apiServer.basePath}/schemas`, { withCredentials: true }).pipe(
            map(rsp => {
                return rsp.payload;
            }),
            catchError((err) => {
                return throwError(err);
            })
        ).toPromise();
    }

    getSupervisorCategories(): Promise<SupervisorCategory[]> {
        return this.http.get<SupervisorCategoryResponse>(`${environment.apiServer.basePath}/scanned-forms/supervisor-categories`,
            { withCredentials: true })
            .pipe(
                map(rsp => {
                    return rsp.payload;
                }),
                catchError((err) => {
                    console.log(err);
                    return throwError('err');
                })
            ).toPromise();
    }

    getLastInvoiceDate(formCode: string): Promise<Invoice> {

        const params = new HttpParams({
            fromObject: {
                cardNumber: formCode
            }
        });
        return this.http.get<InvoiceResponse>(`${environment.apiServer.basePath}/lastInvoice`,
            { withCredentials: true, params })
            .pipe(
                map(rsp => {
                    return rsp.payload;
                }),
                catchError((err) => {
                    return throwError(err);
                })
            ).toPromise();
    }

    supervisorMark(body: object): Promise<any> {
        return this.http.put<GenericResponse<object>>(`${environment.apiServer.basePath}/scanned-forms/supervisor-mark`, body,
            { withCredentials: true })
            .pipe(
                map(rsp => {
                    return rsp.payload;
                }),
                catchError((err) => {
                    console.log(err.error.messages);
                    return throwError(err);
                })
            ).toPromise();
    }


    processedMark(formCodeVal: string, cardNumberVal: string): Promise<any> {
        const params = new HttpParams({
            fromObject: {
                formCode: formCodeVal,
                schemaCode: environment.schemaCode,
                countryCode: environment.countryCode,
                cardNumber: cardNumberVal

            }
        });
        return this.http.post<GenericResponse<object>>(`${environment.apiServer.basePath}/scanned-forms/mark-form-processed`, null,
            { withCredentials: true, params })
            .pipe(
                map(rsp => {
                    return rsp.payload;
                }),
                catchError((err) => {
                    console.log('Mark form processed error:' + err);
                    return throwError(err);
                })
            ).toPromise();
    }

    createMember(member: Member): Promise<any> {
        return this.http.post(`${environment.apiServer.basePath}/scanned-forms/create-or-update`, member, { withCredentials: true })
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            ).toPromise();
    }



    // authentication services
    logout(): Promise<any> {
        return this.http.post(`${environment.apiServer.basePath}/logmeout`, null, { withCredentials: true })
            .pipe(
                tap(rsp => {
                    console.log('user logged out');
                })
            ).toPromise();
    }

    login(loginObj: Login): Promise<any> {
        return this.http.post(`${environment.apiServer.basePath}/login`, loginObj, { withCredentials: true, observe: 'response' })
            .pipe(
                tap(rsp => {
                    if (rsp.status === 200) {
                        return Promise.resolve(true);
                    }
                }), catchError((err) => {
                    return throwError(err);
                })
            ).toPromise();
    }

    isLoggedIn(): Promise<any> {
        return this.http.get(`${environment.apiServer.basePath}/isLoggedIn`, { withCredentials: true }).toPromise();
    }

    lookupTown = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            switchMap(term => {
                if (term.length < 2) {
                    return [];
                }
                return this.http.get('assets/towns.json').pipe(map((towns: Array<string>) => {
                    return towns.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10);
                }));
            }),
            catchError(() => of([]))
        )

    getMemberByCard(cardNumberValue: string): Promise<Member> {
        const params = new HttpParams({
            fromObject: {
                cardNumber: cardNumberValue
            }
        });
        return this.http.get<MemberResponse>(`${environment.apiServer.basePath}/person/byCard`, { withCredentials: true, params })
            .pipe(
                map(rsp => {
                    return rsp.payload;
                }), catchError((err) => {
                    return throwError(err);
                })
            ).toPromise();
    }

    getUserHistory(): Promise<any> {
        return this.http.get<GenericResponse<any>>(`${environment.apiServer.basePath}/scanned-forms/history`, { withCredentials: true })
            .pipe(
                map(rsp => {
                    return rsp.payload;
                }), catchError((err) => {
                    return throwError(err);
                })
            ).toPromise();

    }


    updateMemberAttribute(cardNumberValue: string): Observable<any> {
        const params = new HttpParams({
            fromObject: {
                cardNumber: cardNumberValue
            }
        });
        return this.http.put<Observable<any>>(`${environment.apiServer.basePath}/scanned-forms/update-member-attribute`, null,
            { withCredentials: true, params })
            .pipe(
                catchError((err) => {
                    console.log(err);
                    return throwError(err);
                })
            );

    }
}
