import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SupervisorCategory } from '../common/dto/supervisor.category.model';
import { APIService } from '../api.service';
import { FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SupervisorMark } from '../common/dto/supervisor.mark.model';


@Component({
  selector: 'app-supervisor',
  templateUrl: './supervisor.component.html',
  styleUrls: ['./supervisor.component.scss']
})
export class SupervisorComponent implements OnInit {
  categories: SupervisorCategory[];
  @Input() public formCode: any;
  @Output() submitForm: EventEmitter<boolean> = new EventEmitter(false);
  @Output() supervisorMark: EventEmitter<SupervisorMark> = new EventEmitter();

  supervisorForm = this.fb.group({
    supervisorCategoryId: [''],
    remarks: [''],
    supervisorFlag: [''],
    formCode: ['']
  });

  constructor(public activeModal: NgbActiveModal, private apiService: APIService, private fb: FormBuilder, private toastr: ToastrService
  ) { }

  ngOnInit() {
    if (this.formCode) {
      this.supervisorForm.get('formCode').setValue(this.formCode);
    }

    this.supervisorForm.get('supervisorFlag').setValue(true);
    this.apiService.getSupervisorCategories().then((cat) => {
      this.categories = cat;
    }).catch((err) => {
      console.log('Could not fetch supervisor categories', err);
    });


  }

  setRemarks(value: string) {
    for (const cat of this.categories) {
      if (cat.supervisorCategoryText === 'UNSIGNED' && Number(value) === cat.id) {
        this.supervisorForm.get('remarks').setValue('UNSIGNED');
        break;
      } else {
        this.supervisorForm.get('remarks').setValue('');
      }
    }
  }

  closeModal() {
    this.activeModal.close('Modal Closed');
  }

  onSubmit() {
    if (this.supervisorForm.valid) {
      this.submitForm.emit(true);
      this.supervisorMark.emit(this.supervisorForm.value as SupervisorMark);
      this.closeModal();

    }
  }

}
