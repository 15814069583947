import { Injectable } from '@angular/core';
import { APIService } from '../api.service';
import { UserInfo } from './dto/userinfo.model';
import { Login } from './dto/login.model';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AuthService {


    userInfo: UserInfo;

    constructor(private apiService: APIService) { }

    async isLoggedIn(): Promise<any> {
        return await this.apiService.isLoggedIn();
    }

    async login(loginObj: Login): Promise<boolean> {
        return this.apiService.login(loginObj);

    }

    async logout(): Promise<any> {
        return this.apiService.logout();
    }
}
