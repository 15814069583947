import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { APIService } from '../api.service';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit {
  @Output() formToEdit: EventEmitter<any> = new EventEmitter();
  historyData: [];

  constructor(public activeModal: NgbActiveModal, private apiService: APIService) { }

  async ngOnInit() {
    this.historyData = [];
    await this.apiService.getUserHistory().then((data) => {
      this.historyData = data;
    }).catch((err) => {
      console.log('Could not fetch user history');
    });
  }
  closeModal() {
    this.activeModal.close('Modal Closed');
  }

  editForm(formCode: string) {
    this.formToEdit.emit(formCode);
    this.closeModal();
  }

}
